<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="white"
        flat
      >
        <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.equipment.distribute') }}</v-toolbar-title>

        <v-spacer />

        <v-btn
          class="mx-1"
          color="primary"
          :disabled="patientSelected.length <= 0 || equipmentSelected <= 0"
          @click="handleDistributeClick"
        >
          {{ $vuetify.lang.t('$vuetify.common.distribute') }}
        </v-btn>

        <v-btn
          class="mx-1 grey lighten-4"
          @click="handleGoBack"
        >
          {{ $vuetify.lang.t('$vuetify.common.goBack') }}
        </v-btn>

      </v-toolbar>
      <v-row>
        <v-col>
          <patient-list />
        </v-col>

        <v-divider vertical></v-divider>

        <v-col
          cols="12"
          md="6"
        >
          <equipment-list />
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
          class="white--text"
          color="error"
          depressed
        >
          {{ $vuetify.lang.t('$vuetify.equipment.scanCodeConfirm') }}
          <v-icon right>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="distributeConfirm"
        persistent
        max-width="800"
      >
        <v-card
          v-if="distributeConfirm"
        >
          <v-card-title class="headline">
            {{ $vuetify.lang.t('$vuetify.equipment.confirmDistribute') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                {{ $vuetify.lang.t('$vuetify.patient.label') }}
              </v-col>
            </v-row>

            <v-row
              class="text-h6"
            >
              <v-col
                cols="2"
              >
                {{ patientSelected[0].patientName }}
              </v-col>

              <v-col
                cols="1"
              >
                <span
                >
                  {{ $vuetify.lang.t(`$vuetify.gender.${patientSelected[0].gender.toLowerCase()}`) }}
                </span>

              </v-col>

              <v-col
                cols="3"
              >
                {{ patientSelected[0].birthday }}
              </v-col>

              <v-col
                cols="6"
              >
                {{ patientSelected[0].address.replaceAll(',', '-') }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                {{ $vuetify.lang.t('$vuetify.equipment.label') }}
              </v-col>
            </v-row>

            <v-row
              class="text-h6"
            >

              <v-col
                cols="4"
              >
                {{ equipmentSelected[0].id }}
              </v-col>

              <v-col
                cols="4"
              >
                {{ equipmentSelected[0].deviceId }}
              </v-col>

              <v-col
                cols="4"
              >
                {{ equipmentSelected[0].templateName }}
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="distributeConfirm = false"
            >
              {{ $vuetify.lang.t('$vuetify.common.cancel') }}
            </v-btn>
            <v-btn
              color="primary darken-1"
              @click="handleDistribueDialog"
            >
              {{ $vuetify.lang.t('$vuetify.common.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import bus from '@/services/bus-event';
  import dialogs from '@/services/dialogs.js';

  export default {
    components: {
      PatientList: () => import('./patient'),
      EquipmentList: () => import('./equ')
    },
    data () {
      return {
        selected: [],
        distributeConfirm: false,
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        },
        patientSelected: [],
        equipmentSelected: []
      }
    },
    computed: {

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.equipment.no'),
            align: 'start',
            sortable: false,
            value: 'realName',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.type'),
            align: 'start',
            sortable: false,
            value: 'type',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.name'),
            align: 'start',
            sortable: false,
            value: 'name',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.phone'),
            align: 'start',
            sortable: false,
            value: 'phone',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.email'),
            align: 'start',
            sortable: false,
            value: 'email',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    mounted () {
      bus.on('patient-change', this.onPatientChange);
      bus.on('equipment-change', this.onEquipmentChange);
    },

    beforeDestroy () {
      bus.off('patient-change', this.onPatientChange);
      bus.off('equipment-change', this.onEquipmentChange);
    },

    methods: {

      ...mapActions({
        equipmentGrant: 'equipment/grant'
      }),

      onPatientChange (data) {
        this.patientSelected = data
      },

      onEquipmentChange (data) {
        this.equipmentSelected = data
      },

      handleDistribute  () {
        this.dialogEquipmentList = true
      },
      handleDistributeClick () {
        this.distributeConfirm = true
      },
      handleGoBack () {
        this.$router.go(-1)
      },
      handleDistribueDialog () {
        this.equipmentGrant({ id: this.equipmentSelected[0].id, params: { pt_id: this.patientSelected[0].id } }).then( () => {
          this.$router.push( { name: 'equipmentList' })
        }).catch( error => {
          if (error.data && error.data.code == 1113) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.notContractedErr'), { duration: 10, type: 'error' })
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
          }
        })
      }
    }
  }
</script>
